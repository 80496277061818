import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide76/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide76/image/img.png"
import slider1 from "@components/pageGuide/guides/guide76/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide76/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide76/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Seguros ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo vender seguros por teléfono bajo una estrategia omnicanal?
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La venta de seguros por teléfono requiere un proceso estructurado para captar y mantener la atención de los clientes. Segmenta tu base de datos para dirigir cada llamada a los perfiles adecuados. Utiliza un guion claro que facilite la comunicación y resalte los beneficios del seguro.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "El rol de las encuestas IVR.",
      },
      {
        key: 2,
        text: "Integra múltiples canales de comunicación."
      },
      {
        key: 3,
        text: " Usa la automatización para segmentar y priorizar a los leads.",
      },
      {
        key: 4,
        text: " Personaliza la oferta según las necesidades del cliente.",
      },
      {
        key: 5,
        text: "Utiliza encuestas para ajustar tu estrategia de ventas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo integrar diferentes canales para complementar la venta telefónica. Aprovecha herramientas digitales para el seguimiento y la personalización del contacto.
          <br /><br />
          Por último, accede a detalles, ejemplos y herramientas descargando la guía. Mejora tu estrategia y optimiza cada interacción.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
